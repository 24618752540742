import React, { useRef, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ArtworkContext } from "./ArtworkContext";
import p5 from "p5";
import "../styles/GenerativeSketch.css";

const GenerativeSketch1 = () => {
  const sketchRef = useRef();
  const navigate = useNavigate();
  const { setArtworkImage } = useContext(ArtworkContext);

  useEffect(() => {
    const sketch = (p) => {
      let particles = [];
      const parNum = 1000;
      let colors = [];
      let deform = false;
      let blobs = []; // Contindrà les taques creades

      p.setup = function () {
        blobs = []; // Assegurar que `blobs` estigui inicialitzat dins `setup`

        // Mida fixa per garantir la resolució de 3000px x 3000px
        const canvasWidth = 1920; // Ajusta la mida en píxels
        const canvasHeight = 1080; // Mida en píxels per a la resolució

        // Crear el canvas amb dimensions fixes
        p.createCanvas(canvasWidth, canvasHeight);

        // Millorar la densitat de píxels per una millor qualitat (300 DPI)
        p.pixelDensity(1);

        p.colorMode(p.RGB, 255, 255, 255, 255);
        p.noStroke();

        colors = [
          { color: p.color(215, 180, 106), weight: 1 }, // #D7B46A
          { color: p.color(236, 236, 236), weight: 2 }, // #ECECEC
          { color: p.color(30, 31, 31), weight: 3 }, // #1E1F1F (Més probabilitat)
        ];

        if (!colors || colors.length === 0) {
          console.error("Error: La llista de colors està buida o no inicialitzada.");
        } else {
          console.log("Colors inicialitzats correctament:", colors);
        }

        for (let i = 0; i < parNum; i++) {
          particles.push(new Particle(p.random(p.width), p.random(p.height), colors, p));
        }
        p.background(30, 31, 31);
      };

      p.draw = function () {
        for (let j = particles.length - 1; j >= 0; j--) {
          if (!isInsideBlob(particles[j].x, particles[j].y)) {
            particles[j].update(deform);
            particles[j].show();
          }
          if (particles[j].finished()) {
            particles.splice(j, 1);
          }
        }

        while (particles.length < parNum) {
          const newParticle = new Particle(p.random(p.width), p.random(p.height), colors, p);
          if (!isInsideBlob(newParticle.x, newParticle.y)) {
            particles.push(newParticle);
          }
        }
      };

      p.mousePressed = function () {
        const color = getWeightedRandomColor(colors);
        if (!color) {
          console.error("Error: El color no és vàlid.");
          return;
        }
        drawBlob(p.mouseX, p.mouseY, color, p);
        blobs.push({ x: p.mouseX, y: p.mouseY, size: 80 }); // Mida reduïda
      };

      p.keyPressed = function () {
        if (p.key === " ") {
          deform = !deform;
        }
      };

      p.windowResized = function () {
        if (p.canvas && p.width > 0 && p.height > 0) {
          p.resizeCanvas(1500, 1500);
          p.background(30, 31, 31);
        } else {
          console.error(
            "Error: el canvas no s'ha inicialitzat correctament o les dimensions no són vàlides."
          );
        }
      };

      function drawBlob(x, y, color, p) {
        p.push();
        p.fill(color);
        p.noStroke();
        const blobSize = p.random(50, 100);
        const points = p.random(8, 12);
        const variation = p.random(0.8, 1.2);

        p.beginShape();
        for (let i = 0; i < points; i++) {
          const angle = p.TWO_PI * (i / points);
          const radius = blobSize * p.random(variation - 0.2, variation + 0.2);
          const px = x + p.cos(angle) * radius;
          const py = y + p.sin(angle) * radius;
          p.curveVertex(px, py);
        }
        p.endShape(p.CLOSE);
        p.pop();
      }

      function isInsideBlob(x, y) {
        for (const blob of blobs) {
          const d = p.dist(x, y, blob.x, blob.y);
          if (d < blob.size / 2) {
            return true;
          }
        }
        return false;
      }

      function getWeightedRandomColor(colors) {
        if (!colors || colors.length === 0) {
          console.error("Error: La llista de colors està buida o no inicialitzada.");
          return p.color(255, 255, 255); // Retorna blanc com a fallback
        }

        let totalWeight = 0;
        colors.forEach((c) => {
          if (!c.color || typeof c.weight !== "number") {
            console.error("Error: Color o pes no definit correctament:", c);
          } else {
            totalWeight += c.weight;
          }
        });

        let randomValue = p.random(totalWeight);

        for (const c of colors) {
          if (randomValue < c.weight) {
            return c.color;
          }
          randomValue -= c.weight;
        }

        return colors[colors.length - 1].color; // Retornar l'últim per seguretat
      }

      class Particle {
        constructor(x, y, colors, p) {
          this.x = x;
          this.y = y;
          this.p = p;
          this.lifespan = p.random(50, 150);
          this.size = p.random(3, 7);
          this.color = getWeightedRandomColor(colors);
        }

        update(deform) {
          if (deform) {
            this.x += this.p.random(-10, 10);
            this.y += this.p.random(-10, 10);
          } else {
            this.x += this.p.random(-2, 2);
            this.y += this.p.random(-2, 2);
          }
          this.lifespan -= 1;
        }

        show() {
          this.p.fill(this.color);
          this.p.ellipse(this.x, this.y, this.size);
        }

        finished() {
          return this.lifespan <= 0;
        }
      }
    };

    const p5Instance = new p5(sketch, sketchRef.current);

    return () => {
      p5Instance.remove();
    };
  }, []);

  const handleFinish = async () => {
    const canvasElement = sketchRef.current.querySelector("canvas");
    if (!canvasElement) {
      console.error("Error: No s'ha trobat el canvas per capturar la imatge.");
      return;
    }

    const base64Image = canvasElement.toDataURL("image/png", 0.8).split(",")[1]; //  Redueix la qualitat al 80%
    console.log("Mida de la imatge en base64:", base64Image.length);

    if (!base64Image) {
      console.error("Error: La imatge no s'ha capturat correctament.");
      alert("Hi ha hagut un problema capturant la imatge. Torneu-ho a provar.");
      return;
    }

    try {
      const response = await fetch("http://localhost:4000/api/printful/upload", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ base64Image }),
      });

      if (!response.ok) {
        throw new Error("Error enviant la imatge al backend.");
      }

      const data = await response.json();
      console.log("Fitxer pujat a Printful amb ID:", data.fileId);

      // Navega només si la imatge s'ha pujat correctament
      setArtworkImage(canvasElement.toDataURL("image/png"));
      navigate("/choose-your-artwork-size");
    } catch (error) {
      console.error("Error durant la pujada de la imatge:", error);
      alert("Hi ha hagut un problema pujant la imatge. Torneu-ho a provar.");
    }
  };

  return (
    <div className="generative-sketch-container">
      <div ref={sketchRef}></div>
      <button onClick={handleFinish} className="finish-button">
        Finish Personalization
      </button>
    </div>
  );
};

export default GenerativeSketch1;
