import React, { useContext } from "react";
import { ArtworkContext } from "./ArtworkContext";
import "../styles/ChooseYourArtworkSize.css";

const ChooseYourArtworkSize = () => {
  const { artworkImage } = useContext(ArtworkContext);

  return (
    <div className="choose-artwork-container">
      <h1 className="choose-title">Choose Your Artwork Size</h1>
      <div className="mockup-gallery">
        {artworkImage?.mockups?.map((mockup, index) => (
          <div key={index} className="mockup-item">
            <img src={mockup.url} alt={`Mockup ${index + 1}`} />
            <p>{mockup.variant_name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChooseYourArtworkSize;
