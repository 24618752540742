// src/firebase/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore"; // Afegeix aquesta línia per importar Firestore

const firebaseConfig = {
  apiKey: "AIzaSyAwrnSb-V8xbh2SR5dQaEiHwdk9MXwhCb4",
  authDomain: "aborrasdesign-b281d.firebaseapp.com",
  projectId: "aborrasdesign-b281d",
  storageBucket: "aborrasdesign-b281d.appspot.com",
  messagingSenderId: "217056402289",
  appId: "1:217056402289:web:fcdeceadfa0a7ebd4834d0",
  measurementId: "G-61DM4VL3HM",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app); // Exporta l'autenticació
export const db = getFirestore(app); // Exporta Firestore com 'db'

export default app;
